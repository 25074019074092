import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import NoMatch from './pages/NoMatch/NoMatch';
import SwapToken from './pages/SwapToken';
import CreateToken from './pages/CreateToken';
import Leaderboard from './pages/Leaderboard';
import Portfolio from './pages/Portfolio';
import './App.css';
import toast, { Toaster } from "react-hot-toast";

const App = () => {
    const location = useLocation();

    return (
        <div className="min-h-screen bg-black text-white">
            <div className="bg-[#000e21] min-h-screen w-full px-4">
                <Toaster
                  position="top-center"
                  reverseOrder={false}
                />
                <Header />
                <main>
                    <TransitionGroup>
                        <CSSTransition
                            key={location.key}
                            classNames="fade"
                            timeout={300}
                        >
                            <Routes location={location}>
                                <Route path="/" element={<Dashboard />} />
                                {/* <Route path="/portfolio" element={<Portfolio />} />
                                <Route path="/leader-board" element={<Leaderboard />} /> */}
                                <Route path="/swap" element={<SwapToken />} />
                                <Route path="/create-token" element={<CreateToken />} />
                                <Route path="/swap/:CA" element={<SwapToken />} />
                                <Route path="*" element={<NoMatch />} /> {/* Catch-all route for 404 */}
                            </Routes>
                        </CSSTransition>
                    </TransitionGroup>
                </main>
            </div>
        </div>
    );
};

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;
