import React, { useEffect, useState } from 'react';
import './SwapToken.css';
import UserChatItem from '../../components/UserChatItem';
import ChatInputBar from '../../components/ChatInputBar';
import { useAccount } from 'wagmi';
import axios from 'axios';

const SwapChatView = ({ CA }) => {
    const numberOfUsers =   10;

    const [messages, setMessages] = useState([]);

    const [loading, setLoading] = useState(false);

    const { address } = useAccount();

    const postMessage = async () => {

    }

    const getMessages = async () => {
      try {
        setLoading(true)
        let messages = (await axios.get(`https://evterminal-backend.vercel.app/v1/message/${CA}`)).data;
        setMessages(messages);
        console.log(messages);
      } catch (e) {

      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      getMessages() 
    }, [CA])

    return (
        <div className="swapchat-view-container">
            <div>             
                <div className='swapchat-items-list'>
                    {loading ? <></> : Array.from({ length: messages.length }, (_, index) => (
                        <div
                            key={index}
                            className={`${index % 2 === 0 ? 'bg-[#16222B]' : 'bg-[#12171D]'} hover:cursor-pointer`}
                        >
                            <UserChatItem user={messages[index].user} content={messages[index].content} createdAt={messages[index].createdAt}/>
                        </div>
                    ))}
                </div>
            </div>
            {address ? <ChatInputBar CA={CA} setMessages={setMessages}/> : <></>}
        </div>
    );
};

export default SwapChatView;
