import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedOption: 'first',
};

const radioSlice = createSlice({
  name: 'radio',
  initialState,
  reducers: {
    toggleOption: (state) => {
      state.selectedOption = state.selectedOption === 'first' ? 'second' : 'first';
    },
  },
});

export const { toggleOption } = radioSlice.actions;
export default radioSlice.reducer;
