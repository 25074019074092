import React, { useEffect, useState } from 'react';
import TokenCard from '../../../components/TokenCard';
import SearchBar from '../../../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setActiveLink } from '../../../store/activeLinkSlice';
import axios from 'axios';
import '../Dashboard.css';
import { ethers } from 'ethers';
import Web3 from 'web3';
import erc314ABI from '../../../abis/erc314ABI.json'

const sampleDataItems = [
    { title: "Price", value: "$0.0222" },
    { title: "Market Cap", value: "$2,000,000" },
    { title: "Liquidity", value: "$3,232,222" },
];

const TokenView = () => {
    const numberOfTokens = 2;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchQuery, setSearchQuery] = useState('');
    const [order, setOrder] = useState('Newest');
    const [mktcapOrder, setMktcapOrder] = useState('');

    const [currentTokens, setCurrentTokens] = useState([]);

    const [liquidity, setLiquidity] = useState(0);
    const [price, setPrice] = useState(0);

    const handleTokenClick = (id) => {
        // console.log("=======token event")

        navigate(`/swap/${tokens[id].address}`);
        dispatch(setActiveLink("swap"));
    };

    const handleTokenClickEV = () => {
      navigate(`/swap/0x846c8325500b20f60b009Af666e9f74A5c22494E`);
      dispatch(setActiveLink("swap"));
    };

    const [loading, setLoading] = useState(true);

    const [tokens, setTokens] = useState([]);

    const calculatePrice = async (token, ethPrice) => {
      try {
        const web3 = new Web3('https://ethereum.publicnode.com');
        let contract = new web3.eth.Contract(erc314ABI, token.address);
        let data = await contract.methods.getReserves().call();
        return {
          ...token,
          liquidity: Number(data[0]) / Math.pow(10, 18) * ethPrice * 2,
          price: Number(data[0]) / Number(data[1]) * ethPrice
        }
        
      } catch (e) {

      } 
    }

    const ascendingComp = (token1, token2) => {
      return token2.price - token1.price;
    }

    const descendingComp = (token1, token2) => {
      return token1.price - token2.price;
    }

    const getTokens = async () => {
      try {
        setLoading(true);
        let tokens = (await axios.get('https://evterminal-backend.vercel.app/v1/token')).data.reverse();
        let ethPrice = (
          await axios.get(
            "https://api.dexscreener.com/latest/dex/pairs/ethereum/0x88e6a0c2ddd26feeb64f039a2c41296fcb3f5640"
          )
        ).data.pair.priceUsd;

        tokens = await Promise.all(tokens.map(async (token) => calculatePrice(token, ethPrice)));
        // console.log(tokens);
        setTokens(tokens);
        setCurrentTokens(tokens);

        const web3 = new Web3('https://ethereum.publicnode.com');
        let contract = new web3.eth.Contract(erc314ABI, '0x846c8325500b20f60b009Af666e9f74A5c22494E');
        let data = await contract.methods.getReserves().call();
        setLiquidity(Number(data[0]) / Math.pow(10, 18) * ethPrice * 2);
        setPrice(Number(data[0]) / Number(data[1]) * ethPrice);
      } catch (e) {

      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      getTokens();
    }, []);

    let reverseOrder = () => {
      if (order == 'Newest') {
        setTokens(currentTokens.toReversed());
      } else {
        setTokens(currentTokens);
      }
    }

    let sortByMarketcap = (order) => {
      let result;
      if (order == 'ascending') {
        result = currentTokens.toSorted(ascendingComp);
      } else {
        result = currentTokens.toSorted(descendingComp);
      }
      setTokens(result);
    }

    useEffect(() => {
      if (searchQuery == '') setTokens(currentTokens)
      if (!ethers.isAddress(searchQuery)) {
        let result = currentTokens.filter((token) => token.name.toLowerCase().includes(searchQuery.toLowerCase()));
        setTokens(result);
      } else if (ethers.isAddress(searchQuery)) {
        let result = currentTokens.filter((token) => token.address.toLowerCase() == searchQuery.toLowerCase());
        setTokens(result);
      }
    }, [searchQuery])

    return (
        <div className="token-card-list pt-1 rounded-[10px]">
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} order={order} setOrder={setOrder} reverseOrder={reverseOrder} sortByMarketcap={sortByMarketcap} mktcapOrder={mktcapOrder} setMktcapOrder={setMktcapOrder} />
            <div className='mt-4'>
            {loading ? <> </> : <div
                className={`bg-[#12171D] hover:bg-[#06090C] hover:cursor-pointer duration-300`}
                onClick={() => handleTokenClickEV()}
            >
                <TokenCard
                    image={'/ev.jpg'}
                    name={'EV'}
                    ticker={'EV'}
                    description={'EV Terminal Token'}
                    CA={'0x846c8325500b20f60b009Af666e9f74A5c22494E'}
                    telegramUrl={'https://t.me/+KFoXgg7dzj0zZWI0'}
                    twitterUrl={'https://x.com/EV_Terminal'}
                    websiteUrl={'https://evterminal.io'}
                    loading={loading}
                    price={price}
                    liquidity={liquidity}
                />
            </div>}
            {loading ? <></> : Array.from({ length: tokens.length }, (_, index) => (
                <div
                    key={index}
                    className={`${index % 2 === 0 ? 'bg-[#16222B]': 'bg-[#12171D]'}  hover:bg-[#06090C] hover:cursor-pointer duration-300`}
                    onClick={() => handleTokenClick(index)}
                >
                    <TokenCard
                        image={tokens[index].logoUrl}
                        name={tokens[index].name}
                        ticker={tokens[index].ticker}
                        description={tokens[index].description}
                        dataItems={sampleDataItems}
                        CA={tokens[index].address}
                        telegramUrl={tokens[index].telegramUrl}
                        twitterUrl={tokens[index].twitterUrl}
                        websiteUrl={tokens[index].websiteUrl}
                        loading={loading}
                        price={tokens[index].price}
                        liquidity={tokens[index].liquidity}
                    />
                </div>
            ))}
            </div>
        </div>
    );
};

export default TokenView;
