import React, { useState, useRef } from 'react';

const FileUpload = ({ onImageSelect, setImage }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
          setImage(file);
            const objectURL = URL.createObjectURL(file);
            setSelectedImage(objectURL);
            if (onImageSelect) onImageSelect(objectURL);
        }
    };

    const handleClick = (event) => {
        event.stopPropagation(); // Prevent form click event
    };

    return (
        <div
            className={`font-zendots border-[2px] border-[#3A739E] bg-transparent text-[14px] 2xl:h-[160px] h-[130px] py-6 text-[#B7B7B7] rounded-tl-[40px] rounded-br-[40px] flex justify-center items-center cursor-pointer relative file-input-wrapper`}
            onClick={handleClick}
        >
            {selectedImage ? (
                <img src={selectedImage} alt="Selected" className="h-full" />
            ) : (
                <img src="images/upload.svg" alt="upload" width={100} height={96} onClick={() => fileInputRef.current && fileInputRef.current.click()} />
            )}
            <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                ref={fileInputRef}
            />
        </div>
    );
};

export default FileUpload;
