import React from 'react';

const CustomInput = ({ label, placeholder, setter, border = "", paddingY = 'py-5', paddingX = 'px-4' }) => {
    let borderClasses = '';
    switch (border) {
        case 'tr':
            borderClasses = 'rounded-tr-[40px] rounded-bl-[10px]';
            break;
        case 'tl':
            borderClasses = 'rounded-tl-[40px] rounded-br-[10px]';
            break;
        case 'br':
            borderClasses = 'rounded-br-[40px] rounded-tl-[10px]';
            break;
        case 'bl':
            borderClasses = 'rounded-bl-[40px] rounded-tr-[10px]';
            break;
        default:
            borderClasses = 'rounded-[10px]';
    }

    return (
        <div className='w-full space-y-1'>
            <div className='font-worksans 2xl:text-[16px] text-[14px] text-[#A0A2A5]'>{label}</div>
            <input
                type="text"
                className={`w-full font-zendots border-[2px] border-[#3A739E] bg-transparent 2xl:text-[14px] xl:text-[12px] text-[10px] ${paddingY} ${paddingX} text-[#B7B7B7] focus:outline-none placeholder-[#41454A] ${borderClasses}`}
                placeholder={placeholder}
                onChange={(e) => setter(e.target.value)}
            />
        </div>
    );
};

export default CustomInput;
