import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleOption } from '../../store/dashboardRadioSlice';
import './Header.css';

const CustomRadioButton = () => {
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.radio.selectedOption);

  const handleClick = () => {
    dispatch(toggleOption());
  };

  return (
    <button
      onClick={handleClick}
      className="radio-button relative h-[38px] w-[90px] flex items-center border border-[3px] border-[#41A0E6] rounded-[100px]"
    > 
      <div className={`absolute circle h-[30px] w-[30px] border border-[5px] border-[#41A0E6] rounded-full transition-transform duration-300 ${selectedOption === "first" ? "move-left" : "move-right"}`}></div>
      <div className='flex-grow flex items-center justify-center relative'>
        <span className={`absolute left-3 text-[16px] text-[#999FA8] font-zendots transition-opacity duration-300 ${selectedOption === "first" ? "opacity-hide fade-out" : "opacity-show fade-in"}`}>2nd</span>
        <span className={`absolute right-4 text-[16px] text-[#999FA8] font-zendots transition-opacity duration-300 ${selectedOption === "first" ? "opacity-show fade-in" : "opacity-hide fade-out"}`}>1st</span>
      </div>
    </button>
  );
};

export default CustomRadioButton;
