import React, { useState } from 'react';

const SearchInput = ({ searchAddress, setSearchAddress }) => {

    const [searchQuery, setSearchQuery] = useState('');

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchClick = () => {
        // Handle search logic here
        console.log('Search clicked with query:', searchQuery);
    };

    return (
        <>
            <input
                className="rounded-[30px] border border-[#41A0E6] bg-[#0B111A] px-6 py-[6px] text-white text-[15px] w-full focus:outline-none"
                type="text"
                placeholder="Search Token"
                value={searchAddress}
                onChange={(e) => {
                  setSearchAddress(e.target.value);
                }}
            />
            {/* <button
                className="rounded-[30px] border border-[#41A0E6] bg-transparent text-[#41A0E6] hover:text-white px-8 py-[6px]"
                onClick={handleSearchClick}
            >
                Search
            </button> */}
        </>
    );
};

export default SearchInput;