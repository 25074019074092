import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './TokenCard.css';
import HeartButton from '../HeartButton';
import Web3 from 'web3';
import axios from 'axios';
import erc314ABI from '../../abis/erc314ABI.json';
import { Link } from 'react-router-dom';

const TokenCard = ({ image, name, ticker, description, dataItems, CA, twitterUrl, telegramUrl, websiteUrl, ethPrice, price, liquidity, loading }) => {
    const [isHeartClicked, setIsHeartClicked] = useState(false);
    
    const handleHeartClick = () => {
        setIsHeartClicked(!isHeartClicked);
    };

    const extractNumber = (value) => {
        const cleanedValue = value.replace(/[^\d.-]/g, '');
        return parseFloat(cleanedValue);
    };

    // const [price, setPrice] = useState(0);
    // const [liquidity, setLiquidity] = useState(0);
    // const [loading, setLoading] = useState(true);

    // const getTokenInfo = async (CA) => {
    //   try {
    //     setLoading(true);
    //     const web3 = new Web3('https://ethereum.publicnode.com');
    //     let contract = new web3.eth.Contract(erc314ABI, CA);
    //     let data = await contract.methods.getReserves().call();
    //     setLiquidity(Number(data[0]) / Math.pow(10, 18) * ethPrice * 2);
    //     setPrice(Number(data[0]) / Number(data[1]) * ethPrice);
        
    //   } catch (e) {

    //   } finally {
    //     setLoading(false);
    //   }
    // }

    // useEffect(() => {
    //   getTokenInfo(CA);
    // }, [CA])

    const numberWithCommas = (x) => {
      const strList = x.split(".");
      if (strList.length > 1) return strList[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + strList[1];
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <div className="token-card-container flex flex-col lg:flex-row bg-transparent overflow-hidden">
            <div className='sm:flex w-full lg:w-1/2'>
                <div className="token-card-image flex justify-center p-4">
                    <img src={image} alt={name ?? "token image"} />
                </div>
                <div className="token-card-description p-4 flex-1">
                    <div className='name font-zendots text-[25px] text-white'>{name}</div>
                    <div className="social-links flex items-center my-4">
                        <div className='rounded-[30px] border border-[#67C6E3] text-[#67C6E3] text-[12px] font-worksans px-4 py-1 mr-4'>
                            Ticker: {ticker}
                        </div>
                        {telegramUrl ? <Link to={telegramUrl}><FontAwesomeIcon icon={faTelegram} className="social-icon mr-3" /></Link> : <></>}
                        {twitterUrl ? <Link to={twitterUrl}><FontAwesomeIcon icon={faTwitter} className="social-icon mr-3" /></Link>: <></>}
                        {websiteUrl ? <Link to={websiteUrl}><FontAwesomeIcon icon={faGlobe} className="social-icon" /></Link> : <></>}
                        
                    </div>
                    <div className='description text-[15px]'>{description}</div>
                </div>
            </div>
            <div className="w-full lg:w-1/2 p-4">
                <div className='flex justify-between'>
                    {/* {dataItems.map((item, index) => ( */}
                        <div className="data-item mb-2 space-y-4">
                            <div className='font-worksans md:text-[20px] text-[14px] text-[#67C6E3] flex justify-center whitespace-nowrap'>Price</div>
                            <div className={`font-worksans md:text-[18px] text-[13px] text-white text-center`}>${loading ? 'Loading' : numberWithCommas(price.toFixed(9))}</div>
                        </div>
                        <div className="data-item mb-2 space-y-4">
                            <div className='font-worksans md:text-[20px] text-[14px] text-[#67C6E3] flex justify-center whitespace-nowrap'>Marketcap</div>
                            <div className={`font-worksans md:text-[18px] text-[13px] text-white text-center`}>${loading ? 'Loading' : numberWithCommas((price * (CA == '0x846c8325500b20f60b009Af666e9f74A5c22494E' ? Math.pow(10, 6) : Math.pow(10, 9))).toFixed(0))}</div>
                        </div>
                        <div className="data-item mb-2 space-y-4">
                            <div className='font-worksans md:text-[20px] text-[14px] text-[#67C6E3] flex justify-center whitespace-nowrap'>Liquidity</div>
                            <div className={`font-worksans md:text-[18px] text-[13px] text-white text-center`}>${loading ? 'Loading' : numberWithCommas(liquidity.toFixed(0))}</div>
                        </div>
                    {/* ))} */}
                </div>
                <div className='flex items-center justify-around text-center pt-4 space-y-2'>
                    {/* <div className='relative w-[30px] h-[30px] pr-8'>
                        <HeartButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handleHeartClick();
                            }}
                            isClicked={isHeartClicked}
                        />
                    </div>
                    <div className="font-zendots md:text-[15px] text-[12px] rounded-[10px] bg-[#2B3F4E] flex justify-center items-center md:py-3 py-1 md:px-4 px-2 md:space-x-4 space-x-2 mb-2 md:mb-0">
                        <img src={'/images/token_view.png'} alt="views badge" className='md:w-[30px] md:h-[20px] w-[20px] h-[15px]' />
                        <span className='whitespace-nowrap'>300 views</span>
                    </div> */}
                    {/* <div className="font-zendots md:text-[15px] text-[12px] rounded-[10px] bg-[#2B3F4E] flex justify-center items-center md:py-3 py-1 md:px-4 px-2 md:space-x-4 space-x-2 mb-2 md:mb-0"> */}
                        {/* <img src={'/images/comments.png'} alt="comments badge" className='md:w-[30px] md:h-[25px]  w-[18px] h-[16px]' /> */}
                        {/* <span className='whitespace-nowrap'>0 comments</span>
                    </div> */}
                </div>
            </div>

        </div>
    );
};

export default TokenCard;
