import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import SearchInput from './SearchInput';

const SearchBar = ({ setSearchQuery, order, setOrder, reverseOrder, sortByMarketcap, mktcapOrder, setMktcapOrder }) => {
    
    const [filterExpanded, setFilterExpanded] = useState(false);

    return (
        <div className="w-full px-10 pt-6 font-worksans">
            <div className='flex items-center space-x-2'>
              <SearchInput setSearchAddress={setSearchQuery} />
              <button onClick={() => setFilterExpanded(filterExpanded => !filterExpanded)}>
                  <FontAwesomeIcon icon={faSliders} className="text-[#41A0E6] text-[30px] hover:text-white" />
              </button>
            </div>
            {filterExpanded ? 
            <div className='flex items-center space-x-2 mt-2'>
              <button
                className="rounded-[30px] border border-[#41A0E6] bg-transparent text-[#41A0E6] hover:text-white px-2 py-[6px]"
                onClick={() => { reverseOrder(); setOrder(order => (order == 'Newest' ? 'Oldest' : 'Newest')); }}
              >
                {order}
              </button>
              <button
                className="rounded-[30px] border border-[#41A0E6] bg-transparent text-[#41A0E6] hover:text-white px-2 py-[6px]"
                onClick={() => { 
                  if (mktcapOrder == '') {
                    setMktcapOrder('Asc')
                    sortByMarketcap('ascending'); 
                  } else if (mktcapOrder == 'Asc') {
                    setMktcapOrder('Desc');
                    sortByMarketcap('descending');
                  } else {
                    setMktcapOrder('Asc');
                    sortByMarketcap('ascending')
                  }
                }}
              >
                {mktcapOrder == '' ? 'By Marketcap' : `Marketcap ${mktcapOrder}`}
              </button>
            </div> 
            : 
            <></> }
        </div>
    );
};

export default SearchBar;
