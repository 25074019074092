// activeLinkSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = 'dashboard'; // Set your initial active link here

const activeLinkSlice = createSlice({
  name: 'activeLink',
  initialState,
  reducers: {
    setActiveLink: (state, action) => action.payload,
  },
});

export const { setActiveLink } = activeLinkSlice.actions;
export default activeLinkSlice.reducer;
