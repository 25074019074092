import React, { useEffect } from 'react';
import "./TokenProfile.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faPaperPlane, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faClone, } from '@fortawesome/free-regular-svg-icons';
import Web3 from 'web3';
import erc314ABI from '../../abis/erc314ABI.json';
import { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

const TokenProfile = ({ name, ticker, tokenUrl, description, CA, telegramUrl, twitterUrl, websiteUrl }) => {

  const [liquidity, setLiquidity] = useState(0);
  const [price, setPrice] = useState(0);


  const getPoolInfo = async () => {
    try {
      const web3 = new Web3("https://ethereum.publicnode.com");
      let contract = new web3.eth.Contract(erc314ABI, CA);
      let ethPrice = (
        await axios.get(
          "https://api.dexscreener.com/latest/dex/pairs/ethereum/0x88e6a0c2ddd26feeb64f039a2c41296fcb3f5640"
        )
      ).data.pair.priceUsd;
      let data = await contract.methods.getReserves().call();
      //console.log(data);
      setLiquidity(Number(data[0]) / Math.pow(10, 18) * ethPrice * 2);
      setPrice(Number(data[0]) / Number(data[1]) * ethPrice);
    } catch (e) {

    } finally {

    }
  }

  useEffect(() => {
    getPoolInfo();
  }, [])

  const numberWithCommas = (x) => {
    const strList = x.split(".");
    if (strList.length > 1) return strList[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + strList[1];
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

    return (
        <div className="token-profile-container  rounded-[10px]">
            <div className='flex justify-between pl-6 pr-4 pt-6 pb-4 border-b border-[#292929]'>
                <div className='flex text-[18px] font-bold space-x-2 items-center'>
                    <span>{name}/ETH</span>
                </div>
                {/* <div className='space-x-2 flex items-center'>
                    <span className='text-[14px]'>All Markets</span>
                    <FontAwesomeIcon icon={faAngleDown} className="text-white text-[10px] hover:text-white" />
                </div> */}
            </div>
            <div className='flex flex-col items-center px-4 py-4 border-b border-[#292929]'>
                <img src={tokenUrl} alt="ether" width={82} height={82} className='rounded-[6px] mt-1' />
                <div className='rounded-[30px] border border-[#48B2FF] text-[#48B2FF] text-[12px] font-worksans px-2 py-[2px] mt-3 mb-1'>
                    Ticker: {ticker}
                </div>
                <div className='space-x-2 flex items-center'>
                    <div className='2xl:text-[25px] text-[22px] font-zendots'>{name}</div>
                    {CA ? <div onClick={() => {
                        navigator.clipboard.writeText(CA);
                        toast.success("CA Copied", {
                            style: {
                                border: "1px solid #713200",
                                padding: "16px",
                                color: "#00CD22",
                            },
                            position: "top-right",
                            duration: 3000,
                        });
                    }}><FontAwesomeIcon icon={faClone} className="text-[#848484] text-[15px] hover:text-white" /></div> : <></>}
                </div>
                <div className='text-[20px] text-[#676770] text-center font-semibold'>{description}</div>
            </div>
            <div className='flex flex-col items-center px-4 py-4 border-b border-[#292929] 2xl:space-y-3 space-y-1'>
                {/* <div className='flex justify-between w-full pt-2'>
                    <div className='text-[17px] text-[#B5B5B5]'>Wallet</div>
                    <div className='flex space-x-1'>
                        <div className='border border-[#292929] bg-[#121212] rounded-[2px] flex px-2 space-x-1 items-center'>
                            <img src="/images/metamask.svg" alt="meta mask" width={20} height={20} />
                            <span className='text-[14px]'>0x4...iu3</span>
                        </div>
                        <img src="/images/wallet-cpicon.svg" alt="meta mask" width={16} height={16} />
                    </div>
                </div>
                <div className='flex justify-between w-full'>
                    <div className='text-[17px] text-[#B5B5B5]'>Balance</div>
                    <div className='text-[15px]'>
                        5.7 ETH
                    </div>
                </div> */}
                <div className='flex justify-between w-full'>
                    <div className='text-[17px] text-[#B5B5B5]'>Price</div>
                    <div className='text-[15px]'>
                        ${numberWithCommas(price.toFixed(10))}
                    </div>
                </div>
                <div className='flex justify-between w-full'>
                    <div className='text-[17px] text-[#B5B5B5]'>Market Cap</div>
                    <div className='text-[15px]'>
                        ${numberWithCommas((price * (CA == '0x846c8325500b20f60b009Af666e9f74A5c22494E' ? Math.pow(10, 6) : Math.pow(10, 9))).toFixed(2))}
                    </div>
                </div>
                <div className='flex justify-between w-full'>
                    <div className='text-[17px] text-[#B5B5B5]'>Liquidity</div>
                    <div className='text-[15px]'>
                        ${numberWithCommas(liquidity.toFixed(2))}
                    </div>
                </div>
            </div>
                <div className='flex px-4 py-7 justify-between items-center'>
                  {!twitterUrl ? <></> : <Link to={twitterUrl}>
                    <div className='bg-[#121212] border-[2px] border-[#292929] rounded-[7px] flex justify-center items-center space-x-1 px-3 py-1 hover:text-black hover:bg-white transition duration-150 ease-in-out'>
                        <div><FontAwesomeIcon icon={faXTwitter} className="text-[14px]" /></div>
                        <div className='text-[13px] font-inter font-bold'>Twitter</div>
                    </div>
                  </Link>}
                  {!websiteUrl ? <></> : <Link to={websiteUrl}>
                    <div className='bg-[#121212] border-[2px] border-[#292929] rounded-[7px] flex justify-center items-center space-x-1 px-3 py-1 hover:text-black hover:bg-white transition duration-150 ease-in-out'>
                        <div><FontAwesomeIcon icon={faGlobe} className="text-[14px]" /></div>
                        <div className='text-[13px] font-inter font-bold'>Website</div>
                    </div>
                  </Link>}
                  {!telegramUrl ? <></> : <Link to={telegramUrl}>
                    <div className='bg-[#121212] border-[2px] border-[#292929] rounded-[7px] flex justify-center items-center space-x-1 px-3 py-1 hover:text-black hover:bg-white transition duration-150 ease-in-out'>
                        <div><FontAwesomeIcon icon={faPaperPlane} className="text-[14px]" /></div>
                        <div className='text-[13px] font-inter font-bold'>Telegram</div>
                    </div>
                  </Link>}
                </div>
        </div>
    );
};

export default TokenProfile;
