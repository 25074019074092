import { configureStore } from '@reduxjs/toolkit';
import activeLinkReducer from './activeLinkSlice';
import radioReducer from './dashboardRadioSlice';

const store = configureStore({
    reducer: {
        activeLink: activeLinkReducer,
        radio: radioReducer,
    },
});

export default store;
