import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveLink } from '../../store/activeLinkSlice';
import CustomRadioButton from './CustomRadioButton';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { WagmiConfig, useAccount, useBalance } from 'wagmi';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { arbitrum, mainnet, sepolia } from 'viem/chains';



const HeaderContent = ({ activeLink, handleLinkClick, showRadio, isMobileMenuOpen, toggleMobileMenu }) => {
  const { address, isConnected } = useAccount();
  const { data: balanceData } = useBalance({ address });

  const closeAll = () => { };

  return (
    <>
      <header
        className={`bg-transparent text-white ${'xl:mx-28 pt-7'
          } lg:mx-16 md:mx-2 mx-4 pb-5`}
      >
        <div className="flex items-center justify-evenly">
          <div className="flex items-center xl:space-x-10 lg:space-x-4 md:space-x-2 space-x-8">
            <img src="/logo.svg" alt="Logo" width={40} height={45} />
            {/* {activeLink === 'dashboard' && showRadio && <CustomRadioButton />} */}
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:block">
            <ul className="flex items-center xl:space-x-10 lg:space-x-4 md:space-x-2 font-zendots text-[15px] font-bold">
              <li>
                <NavLink
                  to="/"
                  className={(navData) => (navData.isActive ? 'underline' : '')}
                  onClick={() => handleLinkClick('dashboard')}
                >
                  DASHBOARD
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/swap/0x846c8325500b20f60b009Af666e9f74A5c22494E"
                  className={(navData) => (navData.isActive ? 'underline' : '')}
                  onClick={() => handleLinkClick('swap/0x846c8325500b20f60b009Af666e9f74A5c22494E')}
                >
                  SWAP
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/portfolio"
                  className={(navData) => (navData.isActive ? 'underline' : '')}
                  onClick={() => handleLinkClick('portfolio')}
                >
                  PORTFOLIO
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/leader-board"
                  className={(navData) => (navData.isActive ? 'underline' : '')}
                  onClick={() => handleLinkClick('leader-board')}
                >
                  LEADERBOARD
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/create-token"
                  className={(navData) =>
                    navData.isActive
                      ? 'underline'
                      : ''
                  }
                  onClick={() => handleLinkClick('create-token')}
                >
                  LAUNCH TOKEN
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="https://docs.evterminal.io"
                  className={(navData) => ''}
                  onClick={() => {  }}
                >
                  DOCS
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className='relative hidden md:flex items-center justify-center text-[14px] text-[#04CCE9] font-zendots hover:text-white hover:cursor-pointer'>
            <div className={`absolute border border-[#0385FF] rounded-[20px] px-4 py-2 text-center whitespace-nowrap ${isConnected ? "opacity-0" : "opacity-100"}`}>
              Connect Wallet
            </div>
            <div onClick={closeAll} className={`${isConnected ? "opacity-100" : "opacity-0"}`}>
              <w3m-button />
            </div>
          </div>


          {/* Mobile Menu Toggle Button */}
          <div className='flex flex-col space-y-2 items-end'>
            <button
              className="md:hidden text-white focus:outline-none"
              onClick={toggleMobileMenu}
            >
              {isMobileMenuOpen ? (
                <FontAwesomeIcon
                  icon={faXmark}
                  className="text-white text-[20px] hover:text-white"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faBars}
                  className="text-white text-[20px] hover:text-white"
                />
              )}
            </button>
            {/* <div className='relative md:hidden items-center justify-center sm:text-[12px] text-[10px] text-[#04CCE9] font-zendots hover:text-white hover:cursor-pointer'>
              <div className={`absolute border border-[#0385FF] rounded-[20px] sm:px-4 px-[14px] py-2 text-center whitespace-nowrap ${isConnected ? "opacity-0" : "opacity-100"}`}>
                Connect Wallet
              </div>
              <div onClick={closeAll} className={`${isConnected ? "opacity-100" : "opacity-0"}`}>
                <w3m-button />
              </div>
            </div> */}
          </div>
        </div>

        {/* Mobile Navigation - Side Modal */}
        <div
          className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-transform transform ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
            } md:hidden`}
        >
          <div className="fixed inset-y-0 right-0 w-64 bg-gray-800 shadow-lg overflow-y-auto">
            <div className="p-4 flex items-center justify-between">
              <span className="text-white text-lg font-bold">Menu</span>
              <button
                className="text-white focus:outline-none"
                onClick={toggleMobileMenu}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <ul className="flex flex-col space-y-4 text-center font-zendots text-[12px] p-4">
              <li>
                <NavLink
                  to="/"
                  className={(navData) => (navData.isActive ? 'underline' : '')}
                  onClick={() => handleLinkClick('dashboard')}
                >
                  DASHBOARD
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/swap"
                  className={(navData) => (navData.isActive ? 'underline' : '')}
                  onClick={() => handleLinkClick('swap/0x846c8325500b20f60b009Af666e9f74A5c22494E')}
                >
                  SWAP
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/create-token"
                  className={(navData) => (navData.isActive ? 'underline' : '')}
                  onClick={() => handleLinkClick('create-token')}
                >
                  CREATE TOKEN
                </NavLink>
              </li>
            </ul>
            <div className='flex relative md:hidden items-center justify-center sm:text-[12px] text-[10px] text-[#04CCE9] font-zendots hover:text-white hover:cursor-pointer'>
              <div className={`w-[90%] justify-center items-center absolute border border-[#0385FF] rounded-[20px] sm:px-4 px-[14px] py-2 text-center whitespace-nowrap ${isConnected ? "opacity-0" : "opacity-100"}`}>
                Connect Wallet
              </div>
              <div onClick={closeAll} className={`${isConnected ? "opacity-100" : "opacity-0"} flex flex-col justify-center items-center w-full`}>
                <w3m-button/>
              </div>
            </div>
          </div>
          
        </div>
      </header>
    </>
  );
};

const Header = () => {
  const dispatch = useDispatch();
  const activeLink = useSelector((state) => state.activeLink);
  const [showRadio, setShowRadio] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLinkClick = (link) => {
    dispatch(setActiveLink(link));
    setShowRadio(link === 'dashboard' ? true : false);
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    console.log('activeLink: ', activeLink);
  }, [activeLink]);

  return (
    
    <HeaderContent
      activeLink={activeLink}
      handleLinkClick={handleLinkClick}
      showRadio={showRadio}
      isMobileMenuOpen={isMobileMenuOpen}
      toggleMobileMenu={toggleMobileMenu}
    />
  );
};

export default Header;
