import React from 'react';

const UserChatItem = ({ user, content, createdAt }) => {
    return (
        <div className="px-2 py-1">
            <div className='flex relative py-1 items-center space-x-2 justify-between'>
                <div className='flex space-x-2'>
                  <img src={`/ava${Math.floor(Math.random() * 4) + 1}.png`} alt="chat avatar" width={24} height={24} className='rounded-[50px] border border-[#67C6E3]'/>
                  <span className='font-zendots text-[14px] py-[1px] px-1 border-1 bg-[#228B22] rounded-[5px]'>{user}</span>
                </div>
                {/* <div className='rounded-[30px] border border-[#67C6E3] text-[#67C6E3] text-[10px] font-worksans px-3 py-[3px] ml-4'>
                    Net worth: 4M
                </div> */}
                <div className='text-left text-[14px]'>{createdAt ? createdAt.substring(0, 10) : ""}</div>
            </div>
            <div className="text-[17px] font-worksans">{content}</div>
        </div>
    );
};

export default UserChatItem;
