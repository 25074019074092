import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAccount } from 'wagmi';
import axios from 'axios';

const ChatInputBar = ({ CA, setMessages }) => {
    const [text, setText] = useState('');

    const { address } = useAccount();

    const handleInputChange = (e) => {
        setText(e.target.value);
    };

    const handleSendClick = () => {
        // Handle search logic here
        console.log('Search clicked with query:', text);
    };

    let postMessage = async () => {
      let user = address.substring(address.length - 5);
      setMessages(prevMessages => [...prevMessages, { user: user, content: text, token: CA }]);
      await axios.post('https://evterminal-backend.vercel.app/v1/message', {
        user: user,
        content: text,
        token: CA
      });
      setText("");
    }

    return (
        <div className="w-full flex items-center space-x-2 px-2 pt-2 pb-2 font-worksans">
            <input
                className="rounded-[12px] border border-[#41A0E6] bg-[#0B111A] px-2 py-2 text-white text-[14px] w-full focus:outline-none"
                type="text"
                placeholder="Your Comments"
                value={text}
                onChange={handleInputChange}
            />
            <button
                className="rounded-[12px] border border-[#41A0E6] bg-transparent text-[#41A0E6] hover:text-white hover:bg-[#006400] p-2"
                onClick={postMessage}
            >
              Post
            </button>
        </div>
    );
};

export default ChatInputBar;
