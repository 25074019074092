import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import Web3, { eth } from 'web3';
import "./SwapBoard.css";
import { useAccount, useChainId, useContractWrite, useNetwork } from 'wagmi';
import erc314ABI from '../../abis/erc314ABI.json';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';
import { waitForTransaction } from '@wagmi/core'


const SwapBoard = ({ name, ticker, CA }) => {
    const [activeTab, setActiveTab] = useState('buy');
    const [slippage, setSlippage] = useState(10);

    const [amountHolder, setAmountHolder] = useState('');
    const [amount, setAmount] = useState(0);

    const [outAmount, setOutAmount] = useState('0');

    const [ethBalance, setEthBalance] = useState(0);
    const [tokenBalance, setTokenBalance] = useState(0);

    const chainId = useNetwork();
    

    const handleTab = (tab) => {
        setActiveTab(tab);
    };

    const { address } = useAccount();

    const getBalance = async () => {
      try {
        const web3 = new Web3("https://ethereum.publicnode.com");
        let data;
        if (address) {
          data = await web3.eth.getBalance(address);
          setEthBalance(Number(data) / Math.pow(10, 18));
          let contract;
          if (CA) {
            contract = new web3.eth.Contract(erc314ABI, CA);
          }
          if (contract) {
            data = await contract.methods.balanceOf(address).call();
            setTokenBalance(Number(data) / Math.pow(10, 18));
          } else {
            setTokenBalance(0);
          }
        }
      } catch (e) {
        setTokenBalance(0);
      } finally {

      }
    }

    useEffect(() => {
      getBalance();
    }, [address, CA]);

    useEffect(() => {
      try {
        if (name == 'NO TOKEN') return;
        const web3 = new Web3("https://ethereum.publicnode.com");
        let contract = new web3.eth.Contract(erc314ABI, CA);
        contract.methods
          .getAmountOut(amount * 1e18, activeTab == 'buy')
          .call()
          .then((res) => {
            if (Number(res) / 1e18 > 0.00001) setOutAmount(Number(res) / 1e18);
            else setOutAmount(0);
          });
      } catch (e) {

      } finally {

      }
    }, [amount])


    const handleContractWrite = async (contractWrite) => {
      try {
        const data = await contractWrite();
  
        try {
          const tx = await waitForTransaction({ hash: data.hash });
          await getBalance();
          toast.success("Swapped Token Successfully", {
            style: {
              border: "1px solid #713200",
              padding: "16px",
              color: "#00CD22",
            },
            position: "top-right",
            duration: 5000,
          });
        } catch (error) {
          toast.error(error.message, {
            style: {
              border: "1px solid #713200",
              padding: "16px",
              color: "#FF0000",
            },
            position: "top-right",
            duration: 5000,
          });
        }
      } catch (error) {
        toast.error(error.message, {
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#FF0000",
          },
          position: "top-right",
          duration: 5000,
        });
      }
    };
  
    const { writeAsync: buy } = useContractWrite({
      address: CA,
      abi: erc314ABI,
      functionName: "buy",
      args: [ethers.parseEther((Math.floor((outAmount * (1 - Number(slippage / 100)) * 1e10)) / 1e10).toString())/*(0).toString()*/],
      value: ethers.parseEther((Number(amount)).toString()),
    });

    const { writeAsync: sell } = useContractWrite({
      address: CA,
      abi: erc314ABI,
      functionName: "sell",
      args: [ethers.parseEther(amount.toString()), ethers.parseEther((Math.floor((outAmount * (1 - Number(slippage / 100)) * 1e10)) / 1e10).toString())]
    });

    return (
        <div className="swap-board-container w-[100%]">
            <div className='flex space-x-3 font-inter py-2'>
                <button
                    className={`text-[20px] font-bold w-1/2 py-2 ${activeTab === 'buy' ? 'buy-tab-selected' : 'buy-tab'}`}
                    onClick={() => handleTab('buy')}
                >
                    Buy
                </button>
                <button
                    className={`text-[20px] font-bold w-1/2 py-2 ${activeTab === 'sell' ? 'sell-tab-selected' : 'sell-tab'}`}
                    onClick={() => handleTab('sell')}
                >
                    Sell
                </button>
            </div>
            <div className='my-2 px-6 py-4 border-[2px] border-[#292929] w-full rounded-[8px] bg-[#0A0A0A] flex flex-col items-center justify-center space-y-2'>
                <div className='w-full space-y-2 relative'>
                    <div className='border-[2px] border-[#292929]'>
                      <div className='px-4 pt-4 w-full rounded-[8px] bg-[#0F0F0F] flex items-center justify-between'>
                          <div>
                              <div className='text-[25px] font-semibold'>
                                  {activeTab == 'buy' ? 'ETH' : ticker}
                              </div>
                              
                          </div>
                          <div className='text-[25px] font-semibold'>
                              <input
                                placeholder="Amount"
                                type="String"
                                value={amountHolder}
                                onChange={(e) => {
                                  if (e.target.value == '') {
                                    setAmount(0);
                                    setAmountHolder('');
                                  } else {
                                    setAmount(Number(e.target.value) ? Number(e.target.value) : 0);
                                    setAmountHolder(e.target.value);
                                  }
                                }}
                                className="text-right font-semibold text-[#FFF] px-8 flex-1 bg-transparent outline-none w-[100%] rounded-[10px]"
                              >

                              </input>
                          </div>
                      </div>
                      <div className='px-4 pb-4 text-[15px] text-[#B7B7B7] bg-[#0F0F0F]'>Balance: {activeTab == 'buy' ? ethBalance.toFixed(2) : tokenBalance.toFixed(2)}</div>
                    </div>
                    <div className='border-[2px] border-[#292929]'>
                      <div className='px-4 pt-4 w-full rounded-[8px] bg-[#0F0F0F] flex items-center justify-between'>
                       
                        <div>
                            <div className='text-[25px] font-semibold'>
                                {activeTab == 'buy' ? ticker : 'ETH'}
                            </div>
                            
                        </div>
                        <div className='text-[25px] font-semibold'>
                          <input
                            disabled
                            placeholder=""
                            type="String"
                            value={outAmount}
                            className="text-right font-semibold text-[#FFF] px-8 flex-1 bg-transparent outline-none w-[100%] rounded-[10px]"
                          >

                          </input>
                        </div>
                      </div>
                      <div className='px-4 pb-4 text-[15px] text-[#B7B7B7] bg-[#0F0F0F]'>Balance: {activeTab == 'buy' ? tokenBalance.toFixed(2) : ethBalance.toFixed(2)}</div>
                    </div>
                    <button className='absolute flex items-center justify-center border-[2px] border-[#292929] bg-[#0F0F0F] rounded-full p-2 w-10 h-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-[28px]'
                      onClick={(e) => {
                        setActiveTab((tab) => (tab == 'buy' ? 'sell' : 'buy'));
                      }}
                    >
                        <FontAwesomeIcon icon={faRotate} className="text-white text-[24px]" />
                    </button>
                </div>
                <div className='w-full flex flex-col'>
                    <div className='text-[15px] font-inter py-2'>
                        Slippage
                    </div>
                    <div className='flex justify-between space-x-4'>
                        <button className={`border-[2px] border-[#292929] rounded-[10px] text-[15px] py-2 md:px-6 px-2 lg:px-6 xl:px-6 ${slippage === 0.1 ? "bg-[#1A88C9] text-black" : "bg-[#0F0F0F] text-[#B7B7B7]"} duration-500`}
                            onClick={() => setSlippage(0.1)}
                        >
                            0.1%
                        </button>
                        <button className={`border-[2px] border-[#292929] rounded-[10px] text-[15px] py-2 md:px-6 px-2 lg:px-6 xl:px-6 ${slippage === 0.2 ? "bg-[#1A88C9] text-black" : "bg-[#0F0F0F] text-[#B7B7B7]"} duration-500`}
                            onClick={() => setSlippage(0.2)}
                        >
                            0.2%
                        </button>
                        <button className={`border-[2px] border-[#292929] rounded-[10px] text-[15px] py-2 md:px-6 px-2 lg:px-6 xl:px-6 ${slippage === 0.5 ? "bg-[#1A88C9] text-black" : "bg-[#0F0F0F] text-[#B7B7B7]"} duration-500`}
                            onClick={() => setSlippage(0.5)}
                        >
                            0.5%
                        </button>
                        <div className='border-[2px] border-[#292929] rounded-[10px] bg-[#0F0F0F] bg-[#0F0F0F] pr-4'>
                        <input
                            type="text"
                            className='bg-[#0F0F0F] rounded-[10px]  text-[15px] py-2 md:px-4 pl-2 text-[#B7B7B7] w-20 focus:outline-none lg:pl-4 xl:pl-4' 
                            placeholder="Type"
                            value={slippage.toString()}
                            onChange={(e) => { setSlippage(Number(e.target.value)) }}
                        />
                        <span>%</span>
                        </div>
                    </div>
                </div>

            </div>
            <button className='font-inter text-[20px] bg-[#0385FF] rounded-[8px] py-3 text-black font-bold w-full my-2 hover:opacity-70'
              onClick={(e) => {
                if (chainId && chainId.chain && chainId.chain.id == 1) {
                  if (activeTab == 'buy') {
                    handleContractWrite(buy);
                  } else {
                    handleContractWrite(sell);
                  }
                  
                } else {
                  toast.error('Wrong Chain. Please Switch To ETH Mainnet', {
                    style: {
                      border: "1px solid #713200",
                      padding: "16px",
                      color: "#FF0000",
                    },
                    position: "top-right",
                    duration: 5000,
                  });
                  return;
                }
                
              }}  
            >
                Swap Tokens
            </button>

        </div>
    );
};

export default SwapBoard;
