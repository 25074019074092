import React from 'react';
import '../Dashboard.css';
import TokenView from './TokenView';
import ChatView from './ChatView';

const FirstPart = () => {
    return (
        <div className="flex px-4 space-x-4 py-8">
            <div className="md:w-full w-full">
                <TokenView />
            </div>
            {/* <div className="hidden md:block w-1/4">
                <ChatView />
            </div> */}
        </div>
    );
};

export default FirstPart;
