import React from 'react';
import './Dashboard.css';
import { useSelector } from 'react-redux';
import FirstPart from './FirstPart';
import SecondPart from './SecondPart';

const Dashboard = () => {
    const selectedOption = useSelector((state) => state.radio.selectedOption);
    return (
        <>
            {/* {
                selectedOption === 'first'
                    ? <FirstPart />
                    : <SecondPart />
            } */}
            <FirstPart />
        </>

    );
};

export default Dashboard;
